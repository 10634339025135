<template>
  <div class>
    <div
      class="d-flex flex-row align-items-center justify-content-between pb-3"
    >
      <h2 class="m-0">
        <strong>
          <span class>ARKADIA</span>
          <span class="text-muted">CRM</span>
        </strong>
      </h2>
      <div class>
        <modal
          title="Ajouter une affaire"
          subtitle
          btn="Ajouter une affaire"
          id="AddAffaire"
          ref="AddAffaire"
          img="/images/confetti.svg"
          @store="storeAffaire"
        >
          <base-form-row>
            <base-select
              inputText="Société"
              v-model.number="affaire_societe_id"
              :options="data.societes"
              :errors="feedback.affaire_societe_id"
            ></base-select>
            <base-select
              inputText="Zone"
              v-model.number="affaire_zone_id"
              :options="data.zones"
              :errors="feedback.affaire_zone_id"
            ></base-select>
            <base-select-modal
              ref="comptesmanagerselect"
              name="comptesmanager"
              title="Gestion des comptes client"
              placeholder="Compte"
              empty="Sélectionner un compte"
              :description="compteDescription"
              :feedback="feedback.affaire_compte_id"
            >
              <comptes-manager @select="selectCompte"></comptes-manager>
            </base-select-modal>
            <base-select-modal
              v-if="affaire_compte_id"
              ref="clientsmanagerselect"
              name="clientsmanager"
              title="Gestion des clients"
              placeholder="Clients"
              empty="Sélectionner un client"
              :description="clientDescription"
              :feedback="feedback.affaire_client_id"
            >
              <clients-manager
                @select="selectClient"
                :compte_id="affaire_compte_id"
              ></clients-manager>
            </base-select-modal>
            <base-input
              v-model="affaire_libelle"
              inputText="Libellé"
              :errors="feedback.affaire_libelle"
            ></base-input>
            <base-select
              inputText="Activité"
              v-model.number="affaire_activite_id"
              :options="data.activites"
              :errors="feedback.affaire_activite_id"
            ></base-select>
            <base-input-area
              v-model="affaire_details"
              text="Description succinte"
              :errors="feedback.affaire_enjeux_dosi"
            ></base-input-area>
            <Checkbox
              v-model="affaire_enjeux_dosi"
              text="Exposition des salariés à des rayonnements ionisants"
              :errors="feedback.affaire_enjeux_dosi"
              :iconActive="true"
            ></Checkbox>
          </base-form-row>
        </modal>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center" v-if="loading">
        <loader class="m-5"></loader>
        <h2 class="m-0">Chargement...</h2>
      </div>
    </div>
    <div v-if="!loading" class>
      <div
        class="d-flex flex-column flex-md-row rounded shadow-sm p-3 mb-3 align-items-center"
        v-background-2
      >
        <div class="d-flex flex-column w-100">
          <div class="d-flex">
            <span class>
              <strong>{{ affaireEnCours.cptAffairesEncours }}</strong>
            </span>
            <div class="ml-1">Affaires en cours</div>
          </div>
          <div class="d-flex">
            <span class>
              <strong>{{ affaireEnCours.cptAffairesTerminee }}</strong>
            </span>
            <div class="ml-1">Affaires terminée</div>
          </div>
          <div class="d-flex">
            <span class>
              <strong>{{ affaireEnCours.cptAffairesPerdues }}</strong>
            </span>
            <div class="ml-1">Affaires perdues</div>
          </div>
          <div class="d-flex">
            <span class>
              <strong>{{ affaireEnCours.cptAffairesOpportunite }}</strong>
            </span>
            <div class="ml-1">Opportunités</div>
          </div>
        </div>
        <div class="w-100 mt-3 mt-md-0" style="min-width: 200px">
          <div class="d-flex flex-column">
            <div>Transformation %</div>
            <h1 class>
              <strong>{{ tranformation.toFixed(1) }}</strong>
            </h1>
          </div>
        </div>
      </div>
      <DashboardSatisfaction></DashboardSatisfaction>

      <!--

      <div class="d-flex flex-column flex-lg-row justify-content-between">
        <card
          :indicator="
            affaireEnCours.totalMontantsProduits.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) + ' €'
          "
          :loading="loading"
          title="Production totale"
          icon="dollar-sign"
          class="mr-3 mb-3 p-3"
          text="Total des productions enregistrées"
          >Calcul basé sur le production</card
        >
        <card
          :indicator="
            affaireEnCours.totalMontantsProduitsAnnuel.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) + ' €'
          "
          :loading="loading"
          title="Production totale annuelle d'équipe"
          icon="dollar-sign"
          class="mr-3 mb-3 p-3"
          :text="
            affaireEnCours.totalMontantsProduitsAnnuelN1.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) +
            '€ en ' +
            $moment().subtract(1, 'year').format('YYYY')
          "
          >Calcul basé sur le production</card
        >
        <card
          :indicator="
            affaireEnCours.totalMontantsProduitsAnnuelPerso.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) + ' €'
          "
          :loading="loading"
          title="Ma production totale annuelle"
          icon="dollar-sign"
          class="mb-3 p-3"
          :text="
            affaireEnCours.totalMontantsProduitsAnnuelPersoN1.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) +
            '€ en ' +
            $moment().subtract(1, 'year').format('YYYY')
          "
          >Calcul basé sur le production</card
        >
      </div>

		-->

      <!--
      <div class="d-flex flex-column flex-lg-row justify-content-between">
        <card
          :indicator="
            (affaireEnCours.totalMontantsCommandes - affaireEnCours.totalMontantsProduits).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) + ' €'
          "
          :loading="loading"
          title="Reste à produire"
          icon="dollar-sign"
          class="mr-3 mb-3 p-3"
        ></card>
        <card
          :indicator="
            affaireEnCours.totalMontantsChiffre.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) + ' €'
          "
          :loading="loading"
          title="Total chiffré"
          icon="dollar-sign"
          class="mr-3 mb-3 p-3"
          >à venir</card
        >
        <card
          :indicator="
            affaireEnCours.totalMontantsChiffreOpp.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) + ' €'
          "
          :loading="loading"
          title="Opportunité"
          icon="activity"
          class="p-3 mb-3"
          >à venir</card
        >
      </div>
		-->
      <!--
      <div class="rounded shadow-sm mb-2" v-background-2>
        <div class="d-flex flex-column flex-md-row pl-3 pr-3 pt-3 mr-3">
          <span
            @click="tab = 'prod'"
            class="cursor-pointer ml-2 ml-md-0 mb-2"
            :class="{
              'text-muted-graph': tab !== 'prod',
              'border-bottom-graph': tab == 'prod',
            }"
          >
            <h4 class="mb-1">
              <strong>Votre production</strong>
            </h4>
          </span>
          <span
            @click="tab = 'prodTeam'"
            class="cursor-pointer ml-2 ml-md-3 mb-2"
            :class="{
              'text-muted-graph': tab !== 'prodTeam',
              'border-bottom-graph': tab == 'prodTeam',
            }"
          >
            <h4 class="mb-1">
              <strong>Production d'équipe</strong>
            </h4>
          </span>
        </div>

        <div class="row pl-3 pr-3">
          <chart-1
            :labels="data.prod_mensuelle.labels"
            :dataset="data.prod_mensuelle.dataset"
            v-if="!loading && chartLoaded && tab == 'prod'"
          ></chart-1>
          <chart-2
            :labels="data.prod_mensuelle_users.labels"
            :dataset="data.prod_mensuelle_users.dataset"
            v-if="!loading && chartLoaded && tab == 'prodTeam'"
          ></chart-2>
        </div>
      </div>
		-->
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
// import Chart1 from "@/components/affaires/Chart1.vue";
// import Chart2 from "@/components/affaires/Chart2.vue";
import DashboardSatisfaction from "@/components/affaires/DashboardSatisfaction.vue";

import Modal from "@/components/bases/Modal.vue";
// import Card from "@/components/bases/Card.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import Loader from "@/components/bases/Loader.vue";

import BaseInput from "@/components/bases/Input.vue";
import BaseSelectModal from "@/components/bases/SelectModal.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import ComptesManager from "@/components/comptes/Manager.vue";
import ClientsManager from "@/components/clients/Manager.vue";

export default {
  components: {
    // Chart1,
    // Chart2,
    Modal,
    // Card,
    BaseSelect,
    BaseFormRow,
    Loader,
    DashboardSatisfaction,
    BaseInput,
    BaseSelectModal,
    BaseInputArea,
    Checkbox,
    ComptesManager,
    ClientsManager
  },
  data() {
    return {
      loading: false,
      chartLoaded: false,
      stats: {},
      test: 0,
      affaire_societe_id: null,
      affaire_activite_id: null,
      affaire_zone_id: null,
      affaire_compte_id: null,
      affaire_client_id: null,
      affaire_enjeux_dosi: false,
      affaire_aip_eip: false,
      affaire_petrochimique: false,
      affaire_libelle: "",
      affaire_details: "",
      feedback: {},
      tab: "prodTeam",
      affaire_comptes: null,
      affaire_clients: null
    };
  },
  methods: {
    ...mapActions({
      _storeAffaire: "affaires/createAffaire"
    }),
    getDataStats() {
      this.loading = true;
      this.chartLoaded = false;
      this.$http
        .get("/crm/stats")
        .then(response => (this.stats = response.data))
        .catch(error => console.log(error))
        .finally(() => {
          this.loading = false;
          this.chartLoaded = true;
        });
    },
    errHandler: function(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    storeAffaire() {
      this.loading = true;
      this.chartLoaded = false;
      this._storeAffaire({
        affaire_societe_id: this.affaire_societe_id,
        affaire_activite_id: this.affaire_activite_id,
        affaire_zone_id: this.affaire_zone_id,
        affaire_compte_id: this.affaire_compte_id,
        affaire_client_id: this.affaire_client_id,
        affaire_enjeux_dosi: this.affaire_enjeux_dosi,
        affaire_aip_eip: this.affaire_aip_eip,
        affaire_petrochimique: this.affaire_petrochimique,
        affaire_libelle: this.affaire_libelle,
        affaire_details: this.affaire_details
      })
        .then(() => this.$refs.AddAffaire.hide())
        .catch(error => this.errHandler(error))
        .finally(() => {
          this.loading = false;
          this.chartLoaded = true;
        });
    },
    montantProduitParAffaire(affaire) {
      let prods = affaire.affaire_production;
      let montantProduit = 0;

      if (prods && prods.length > 0) {
        prods.forEach(prod => {
          if (prod.montant > 0)
            montantProduit = montantProduit + parseFloat(prod.montant);
        });
      }
      return montantProduit;
    },
    lettreMaj(a) {
      return (a + "").charAt(0).toUpperCase() + a.substr(1);
    },
    selectCompte(compte) {
      this.affaire_compte_id = compte.id;
      this.affaire_comptes = compte;
      this.$refs.comptesmanagerselect.hideModal();
    },
    selectClient(client) {
      this.affaire_client_id = client.id;
      this.affaire_clients = client;
      this.$refs.clientsmanagerselect.hideModal();
    }
  },
  mounted() {
    this.getDataStats();
  },
  computed: {
    ...mapGetters({
      data: "affaires/data"
    }),
    ...mapMultiRowFields("affaires", ["affaires"]),

    compteDescription() {
      if (this.affaire_comptes && this.affaire_comptes.description)
        return this.affaire_comptes.description;
      return "";
    },
    clientDescription() {
      if (
        this.affaire_clients &&
        this.affaire_clients.client_nom &&
        this.affaire_clients.client_prenom
      )
        return (
          this.affaire_clients.client_nom +
          " " +
          this.affaire_clients.client_prenom
        );
      return "";
    },

    /*
    affairesAvecProduction() {
      let affaires = this.affaires;
      let rep = [];

      affaires.forEach((affaire) => {
        const prods = affaire.affaire_production;
        let montantProduit = 0;
        let prodRecente = false;
        if (prods && prods.length > 0) {
          prods.forEach((prod) => {
            if (prod.montant > 0) montantProduit = montantProduit + parseFloat(prod.montant);

            if (prod.mois >= this.$moment().startOf("month").subtract(1, "month").format("YYYY-MM-DD")) {
              prodRecente = true;
            }
          });
        }
        if (affaire.affaire_commande_montant > 0)
          rep.push({
            id: affaire.id,
            affaire_identifiant: affaire.affaire_identifiant,
            affaire_libelle: affaire.affaire_libelle,
            affaire_pilote_id: affaire.affaire_pilote_id,
            affaire_montant_cmd: affaire.affaire_commande_montant,
            affaire_montant_prod: montantProduit,
            affaire_prods: affaire.affaire_production,
            affaire_prod_recente: prodRecente,
          });
      });
      return rep;
    },

    affairesAvecProductionRecente() {
      let affaires = this.affaires;
      let rep = [];
      let totalProduitMoisDernier = 0;

      affaires.forEach((affaire) => {
        const prods = affaire.affaire_production;
        let montantProduit = 0;
        let prodRecente = false;
        if (prods && prods.length > 0) {
          prods.forEach((prod) => {
            if (prod.montant > 0)
              if (prod.mois === this.$moment().startOf("month").format("YYYY-MM-DD")) {
                prodRecente = true;
                montantProduit = montantProduit + parseFloat(prod.montant);

                totalProduitMoisDernier = totalProduitMoisDernier + parseFloat(prod.montant);
              }
          });
        }
        if (affaire.affaire_commande_montant > 0 && prodRecente)
          rep.push({
            id: affaire.id,
            affaire_identifiant: affaire.affaire_identifiant,
            affaire_libelle: affaire.affaire_libelle,
            affaire_pilote_id: affaire.affaire_pilote_id,
            affaire_montant_cmd: affaire.affaire_commande_montant,
            affaire_montant_prod: montantProduit,
            affaire_prods: affaire.affaire_production,
          });
      });
      return { rep: rep, totalProduitMoisDernier: totalProduitMoisDernier };
    },
		*/
    affaireEnCours() {
      let cptAffairesOpportunite = 0;
      let cptAffairesEncours = 0;
      let cptAffairesPerdues = 0;
      let cptAffairesTerminee = 0;
      let totalMontantsCommandes = 0;
      let totalMontantsProduits = 0;
      let totalMontantsProduitsAnnuel = 0;
      let totalMontantsProduitsAnnuelN1 = 0;
      let totalMontantsProduitsAnnuelPerso = 0;
      let totalMontantsProduitsAnnuelPersoN1 = 0;
      let totalMontantsChiffre = 0;
      let totalMontantsChiffreOpp = 0;

      if (this.affaires && this.affaires.length > 0) {
        this.affaires.forEach(affaire => {
          if (affaire.affaire_etat_id === 1) {
            cptAffairesOpportunite = cptAffairesOpportunite + 1;
          }
          if (affaire.affaire_etat_id === 2) {
            cptAffairesEncours = cptAffairesEncours + 1;
          }
          if (affaire.affaire_etat_id === 3) {
            cptAffairesTerminee = cptAffairesTerminee + 1;
          }
          if (affaire.affaire_etat_id === 4) {
            cptAffairesPerdues = cptAffairesPerdues + 1;
          }

          /* totalMontantsCommandes = totalMontantsCommandes + affaire.affaire_commande_montant;

          if (affaire && affaire.affaire_production) {
            affaire.affaire_production.forEach((prod) => {
              if (this.$moment(prod.mois).format("YYYY") === this.$moment().format("YYYY"))
                totalMontantsProduitsAnnuel = totalMontantsProduitsAnnuel + prod.montant;
              totalMontantsProduits = totalMontantsProduits + prod.montant;
            });
          }

          if (affaire && affaire.affaire_phases) {
            affaire.affaire_phases.forEach((phase) => {
              phase.chiffrages.forEach((chiffrage) => {
                totalMontantsChiffre = totalMontantsChiffre + chiffrage.prixVente;
              });
            });
          }

          if (affaire.affaire_etat_id === 1) {
            affaire.affaire_phases.forEach((phase) => {
              phase.chiffrages.forEach((chiffrage) => {
                totalMontantsChiffreOpp = totalMontantsChiffreOpp + chiffrage.prixVente;
              });
            });
          }

          if (affaire.affaire_pilote_id == this.$store.getters["user/data"].id) {
            affaire.affaire_production.forEach((prod) => {
              if (this.$moment(prod.mois).format("YYYY") === this.$moment().format("YYYY"))
                totalMontantsProduitsAnnuelPerso = totalMontantsProduitsAnnuelPerso + prod.montant;
            });
          }

          if (affaire.affaire_pilote_id == this.$store.getters["user/data"].id) {
            affaire.affaire_production.forEach((prod) => {
              if (this.$moment(prod.mois).format("YYYY") === this.$moment().subtract(1, "year").format("YYYY"))
                totalMontantsProduitsAnnuelPersoN1 = totalMontantsProduitsAnnuelPersoN1 + prod.montant;
            });
          }

          affaire.affaire_production.forEach((prod) => {
            if (this.$moment(prod.mois).format("YYYY") === this.$moment().subtract(1, "year").format("YYYY"))
              totalMontantsProduitsAnnuelN1 = totalMontantsProduitsAnnuelN1 + prod.montant;
          });

					*/
        });
      }

      return {
        cptAffairesOpportunite: cptAffairesOpportunite,
        cptAffairesEncours: cptAffairesEncours,
        cptAffairesPerdues: cptAffairesPerdues,
        cptAffairesTerminee: cptAffairesTerminee,
        totalMontantsCommandes: totalMontantsCommandes,
        totalMontantsProduits: totalMontantsProduits,
        totalMontantsProduitsAnnuel: totalMontantsProduitsAnnuel,
        totalMontantsProduitsAnnuelN1: totalMontantsProduitsAnnuelN1,
        totalMontantsProduitsAnnuelPerso: totalMontantsProduitsAnnuelPerso,
        totalMontantsProduitsAnnuelPersoN1: totalMontantsProduitsAnnuelPersoN1,
        totalMontantsChiffre: totalMontantsChiffre,
        totalMontantsChiffreOpp: totalMontantsChiffreOpp
      };
    },

    tranformation() {
      const q1 = this.affaireEnCours.cptAffairesEncours;
      const q2 = this.affaireEnCours.cptAffairesPerdues;
      const q3 = this.affaireEnCours.cptAffairesTerminee;
      return ((q1 + q3) / (q1 + q2 + q3)) * 100;
    }
  }
};
</script>
<style>
.text-muted-graph {
  color: #b6bbbd;
}
.border-bottom-graph {
  border-bottom: 3px solid #eb9d00 !important;
}
</style>
